import moment from "moment";

export const bookingHoursCalc = (start, end) => {
  let rounded =
    Math.round(moment(end).diff(moment(start), "hours", true) * 2) / 2;
  return rounded;
};

export const readableDateString = (dateToConvert) => {
  return moment(dateToConvert).format("dddd Do MMMM YYYY");
};

export const readableTimeString = (dateToConvert) => {
  return moment(dateToConvert).format("h:mm A");
};
