// extracted by mini-css-extract-plugin
export var bookingChoicesWrapper = "booking-module--booking-choices-wrapper--3dJXK";
export var bookingEventDetailsWrapper = "booking-module--booking-event-details-wrapper--A1Ij0";
export var bookingExtras = "booking-module--booking-extras--7JMfz";
export var bookingExtrasWrapper = "booking-module--booking-extras-wrapper--mnYMq";
export var bookingFacilitiesChoices = "booking-module--booking-facilities-choices--W0Zn0";
export var bookingFacilitiesDetails = "booking-module--booking-facilities-details--eh4tl";
export var bookingFacilitiesWrapper = "booking-module--booking-facilities-wrapper--GkjXp";
export var bookingInfoWrapper = "booking-module--booking-info-wrapper--l5xHV";
export var bookingRoomCost = "booking-module--booking-room-cost--78Ob4";
export var bookingRoomInfoImage = "booking-module--booking-room-info-image--fsJ42";
export var bookingRoomSummary = "booking-module--booking-room-summary--WZamq";
export var bookingTotals = "booking-module--booking-totals--2tAq+";
export var bookingTypeChoices = "booking-module--booking-type-choices--f+Yii";
export var choicesLabels = "booking-module--choices-labels--HL9ue";
export var confirmConditionsWrapper = "booking-module--confirm-conditions-wrapper--g+-iM";
export var confirmCostsWrapper = "booking-module--confirm-costs-wrapper--hEKOt";
export var confirmMsgDetails = "booking-module--confirm-msg-details--G8qjK";
export var contactForm = "booking-module--contact-form--j2J5O";
export var contactFormC = "booking-module--contact-form-c--fUEj+";
export var contactFormPC = "booking-module--contact-form-p-c--4yLY7";
export var contactFormPart = "booking-module--contact-form-part--hG8Mm";
export var costTable = "booking-module--cost-table---qVZ3";
export var facilitiesLabels = "booking-module--facilities-labels--GlKEQ";
export var msgConfirmWrapper = "booking-module--msg-confirm-wrapper--LCdLu";
export var msgDetails = "booking-module--msg-details--R0+0c";
export var msgImage = "booking-module--msg-image--simVY";