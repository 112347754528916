import React, { useContext, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { navigate } from "gatsby";

import { BookingContext } from "../../contexts/BookingContext";

import BookingRoomHeader from "../../components/booking/bookingRoomHeader";
import BookingDateTime from "../../components/booking/bookingDateTime";
import BookingRoomSummary from "../../components/booking/bookingRoomSummary";
import BookingRoomCost from "../../components/booking/bookingRoomCost";

import {
  bookingInfoWrapper,
  facilitiesLabels,
  bookingFacilitiesChoices,
  bookingFacilitiesDetails,
  bookingFacilitiesWrapper,
  bookingExtrasWrapper,
} from "../../css/booking.module.css";

const RoomFacilities = () => {
  const [bookingValues, setBookingValues] = useContext(BookingContext);
  const [bookingRoom, setBookingRoom] = useState(null);
  const [, setBookingFacilities] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setBookingRoom(bookingValues.bookingRoom);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom]);

  const onSubmit = async (values) => {
    let tempArr = [];
    for (const [key, value] of Object.entries(values)) {
      tempArr.push({ name: key, cost: value[0] || 0 });
    }

    setBookingFacilities(tempArr);
    // need to set more contexts here
    setBookingValues({ ...bookingValues, bookingFacilities: tempArr });
    // navigate to next page-template event-details
    navigate(`/rooms/${bookingRoom.slug}/book/event-details`);
  };

  return (
    <div className="content-wrapper-column">
      <BookingRoomHeader />
      <BookingDateTime />
      <div className={bookingExtrasWrapper}>
        <div className={bookingFacilitiesWrapper}>
          <h2>Which extra facilities will you need?</h2>
          <div className={bookingFacilitiesDetails}>
            <h3>Already included:</h3>
            <ul>
              <li>Sole access to the room during your booking</li>
              <li>
                Access to our fully equipped kitchen (may be shared with other
                guests)
              </li>
              <li>Free on-site parking</li>
            </ul>
            <Form
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className={bookingFacilitiesChoices}
                >
                  {bookingRoom && (
                    <>
                      {bookingRoom.Facilities ? (
                        <>
                          {bookingRoom.Facilities.facilities.map((item, i) => (
                            <div key={i}>
                              <Field
                                type="checkbox"
                                name={item.name}
                                component="input"
                                value={item.cost}
                              />
                              <div className={facilitiesLabels}>
                                <label htmlFor={item.name}>{item.name}</label>
                                <p>
                                  {item.cost ? `£${item.cost}` : `Free`} -{" "}
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          ))}
                          <button type="submit" className="btn-green-light">
                            Save and continue
                          </button>
                        </>
                      ) : (
                        <button type="submit" className="btn-green-light">
                          Continue
                        </button>
                      )}
                    </>
                  )}
                </form>
              )}
            />
          </div>
        </div>
        <div className={bookingInfoWrapper}>
          <BookingRoomSummary />
          <BookingRoomCost />
        </div>
      </div>
    </div>
  );
};

export default RoomFacilities;
