import React, { useContext, useEffect, useState } from "react";
import ReactMoment from "react-moment";
import { BookingContext } from "../../contexts/BookingContext";

import { bookingDateTime } from "../../css/booking-date-time.module.css";

const BookingDateTime = () => {
  const [bookingValues] = useContext(BookingContext);
  const [bookingDate, setBookingDate] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingDate && setBookingDate(bookingValues.bookingDate);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingDate]);

  return (
    <div className={bookingDateTime}>
      <p>
        <strong>Your selection: </strong>
        <ReactMoment format="dddd Do MMMM YYYY, h:mm A">
          {bookingDate && bookingDate.start}
        </ReactMoment>{" "}
        -{" "}
        <ReactMoment format="dddd Do MMMM YYYY, h:mm A">
          {bookingDate && bookingDate.end}
        </ReactMoment>{" "}
        (&nbsp;
        <ReactMoment
          diff={bookingDate && bookingDate.start}
          unit="hours"
          decimal
        >
          {bookingDate && bookingDate.end}
        </ReactMoment>{" "}
        hours )
      </p>
    </div>
  );
};

export default BookingDateTime;
