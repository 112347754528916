import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../contexts/BookingContext";

const BookingRoomHeader = () => {
  const [bookingValues] = useContext(BookingContext);
  const [room, setRoom] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setRoom(bookingValues.bookingRoom);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom]);

  return (
    <div className="page-header">
      <h1>Request a booking for the {room && room.RoomTitle}</h1>
      <p>Give us more details to complete your request</p>
    </div>
  );
};

export default BookingRoomHeader;
