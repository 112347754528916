import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../contexts/BookingContext";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  bookingExtras,
  bookingRoomInfoImage,
  bookingRoomSummary,
} from "../../css/booking.module.css";

const BookingRoomSummary = () => {
  const [bookingValues] = useContext(BookingContext);
  const [room, setRoom] = useState();
  const [extras, setExtras] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setRoom(bookingValues.bookingRoom);
      bookingValues.bookingFacilities &&
        setExtras(bookingValues.bookingFacilities);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom, bookingValues.bookingFacilities]);

  return (
    <div className={bookingRoomSummary}>
      <h3>{room && room.RoomTitle}</h3>
      <div className={bookingRoomInfoImage}>
        {room && (
          <GatsbyImage
            image={room.roomImage.childImageSharp.gatsbyImageData}
            alt={room.RoomTitle}
            imgStyle={{ objectFit: "cover" }}
            style={{ width: "100%" }}
          />
        )}
      </div>
      <ul>
        {room &&
          room.feature.roomFeatures.map((item, i) => (
            <li key={i}>
              <div>
                <GatsbyImage
                  image={item.featureImageWhite.childImageSharp.gatsbyImageData}
                  alt={item.feature}
                  style={{ marginRight: "0.4rem" }}
                />
                <div>{item.feature}</div>
              </div>
            </li>
          ))}
      </ul>
      {extras && (
        <div className={bookingExtras}>
          {extras.length > 0 && (
            <>
              <h4>Extra additions</h4>
              <ul>
                {extras.map((item, i) => (
                  <li key={i}>{item.name}</li>
                ))}
              </ul>{" "}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingRoomSummary;
