import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../contexts/BookingContext";

import { bookingHoursCalc } from "../../utils/dateUtils";

import { bookingRoomCost } from "../../css/booking.module.css";

const BookingRoomCost = () => {
  const [bookingValues] = useContext(BookingContext);
  const [room, setRoom] = useState();
  const [bookingDate, setBookingDate] = useState(null);
  const [runningTotal, setRunningTotal] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setRoom(bookingValues.bookingRoom);
      bookingValues.bookingDate && setBookingDate(bookingValues.bookingDate);
      // get the hours
      let bHours = 1;
      if (bookingDate) {
        bHours = bookingHoursCalc(bookingDate.start, bookingDate.end);
      }

      bookingValues.bookingRoom &&
        setRunningTotal(
          bookingValues.bookingRoom.prices.map((item) => {
            const { name } = item.bookingType;
            let newCost = item.cost * bHours;
            return { cost: newCost, name };
          })
        );
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom, bookingValues.bookingDate, bookingDate]);

  return (
    <div className={bookingRoomCost}>
      <p>Price for your event</p>
      <ul>
        {runningTotal &&
          runningTotal.map((item, i) => (
            <li key={i}>
              {item.name} use: £{item.cost}{" "}
            </li>
          ))}
      </ul>
      <p>Hire agreement</p>
      <a href={room && room.hireAgreementLink}>
        Download Pannal Village Hall hire agreement
      </a>
      &nbsp;(PDF)
    </div>
  );
};

export default BookingRoomCost;
